import React, { useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import firebase from "firebase/app";
import 'firebase/firestore';

const useStyles = makeStyles({
    modal: {
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed', 
        zIndex: 1, 
        left: 0,
        top: 0,
        width: '100%', 
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.4)', 
    },
    modalContent: {
        backgroundColor: '#fefefe',
        padding: '20px',
        border: '1px solid #888',
        "@media (max-width: 600px)": {
            width: '90%'
        },
        "@media (min-width: 600px)": {
            width: '70%'
        },
        "@media (min-width: 768px)": {
            width: '60%'
        },
        "@media (min-width: 992px)": {
            width: '50%'
        },
        "@media (min-width: 1200px)": {
            width: '30%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 15
    },
    closeBtn: {
        marginTop: 20,
        marginBottom: 20,
        cursor: 'pointer'
    },
    input: {
        marginTop: 15,
        padding: 15,
        width: '52%',
    },
    submitBtn: {
        marginTop: 30,
        marginBottom: 10,
        backgroundColor: '#4CAF50', 
        border: 'none',
        color: 'white',
        padding: '15px 32px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
    }
});

function AddTax({ setShowTax }) {
    const classes = useStyles();

    const [city, setCity] = useState('');
    const [province, setProvince] = useState('');
    const [gst, setGst] = useState('');
    const [pst, setPst] = useState('');
    const [hst, setHst] = useState('');

    const formRef1 = useRef(null);
    const formRef2 = useRef(null);
    const formRef3 = useRef(null);

    const scroll1 = () => {
        window.scrollTo(0, formRef1.current.offsetTop) 
    };

    const scroll2 = () => {
        window.scrollTo(0, formRef2.current.offsetTop) 
    };

    const scroll3 = () => {
        window.scrollTo(0, formRef3.current.offsetTop) 
    };

    const addTax = () => {
        if (city === '' || province === '' || gst === '' || pst === '') {
            return alert('Fill the fields')
        }

        firebase
            .firestore()
            .collection('tax')
            .add({
                city,
                province,
                gst,
                pst,
                hst
            })
            .then(() => {
                setShowTax(false)
            })
            .catch(err => {
                alert(err.message)
            });
    }

  return (
    <div className={classes.modal}>
        <div className={classes.modalContent}>
            <CloseIcon onClick={() => setShowTax(false)} fontSize='medium' color='secondary' className={classes.closeBtn} />
            <input placeholder='City' value={city} onChange={e => setCity(e.target.value)} className={classes.input} />
            <input placeholder='Province' value={province} onChange={e => setProvince(e.target.value)} className={classes.input} />
            <input ref={formRef1} placeholder='Gst' onFocus={scroll1} value={gst} onChange={e => setGst(e.target.value)} className={classes.input} />
            <input ref={formRef2} placeholder='Pst' onFocus={scroll2} value={pst} onChange={e => setPst(e.target.value)} className={classes.input} />
            <input ref={formRef3} placeholder='Hst' onFocus={scroll3} value={hst} onChange={e => setHst(e.target.value)} className={classes.input} />
            <button onClick={addTax} className={classes.submitBtn}>
                Submit
            </button>
        </div>
    </div>
  )
}

export default AddTax