import React, { useState, useRef } from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import firebase from "firebase/app";
import 'firebase/auth';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    },
    formBox: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2rem',
        marginLeft: 'auto', 
        marginRight: 'auto',
        marginTop: '2rem',
        boxShadow: '1px 2px 9px #00cdac',
        "@media (min-width: 400px)": {
           padding: 40,
        },
        padding: 20,
        borderRadius: 20,
    },
    input: {
        fontSize: 20,
        marginBottom: 30,
        padding: 10,
    },
    btn: {
        padding: 10,
        width: 150,
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 8,
        backgroundColor: 'black',
        color: '#fff',
        fontSize: 18
    }
}); 

function Auth() {
    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const formRef = useRef(null);

    const login = async () => {
        if (email === '' || password === '' || email.toLowerCase() !== 'umrashrf@gmail.com') {
            alert('Fields are invalid.');
            return;
        }

        try {
            setLoading(true);
            await firebase.auth().signInWithEmailAndPassword(email, password);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert(err);
        }
    };

    const scroll = () => {
        window.scrollTo(0, formRef.current.offsetTop) 
    }

    if (loading) {
        return <div className={classes.loading}>
            <CircularProgress color="secondary" />
        </div>
    }

  return (
    <div className={classes.container}>
         <Avatar
            alt='logo'
            src={require('../images/logo.png')}
            sx={{ 
                width: 200, 
                height: 200, 
                marginLeft: 'auto', 
                marginRight: 'auto',
                marginTop: '2rem',
            }}
        />
        <div ref={formRef} className={classes.formBox}>
            <input 
                type={'email'} 
                placeholder='Email' 
                value={email} 
                onChange={e => setEmail(e.target.value)} 
                className={classes.input} 
            />
            <input 
                type={'password'} 
                placeholder='Password' 
                value={password} 
                onFocus={scroll} 
                onChange={e => setPassword(e.target.value)} 
                className={classes.input} 
            />
            <button onClick={login} className={classes.btn}>
                Login
            </button>
        </div>
    </div>
  )
}

export default Auth