import React, { useState, useEffect } from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';

import firebase from "firebase/app";
import 'firebase/firestore';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2rem',
    },
    modal: {
        display: 'flex', 
        position: 'fixed', 
        zIndex: 1, 
        left: 0,
        top: 0,
        width: '100%', 
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)', 
        backgroundColor: 'rgba(0,0,0,0.4)' 
    },
    modalContent: {
        backgroundColor: '#fefefe',
        margin: '15% auto', 
        padding: '20px',
        border: '1px solid #888',
        width: '80%' 
    },
    titleItem: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '7.5rem',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    item: {
        display: 'flex',
        "@media (max-width: 992px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        marginLeft:'auto',
        marginRight: 'auto',
        alignItems: 'center',
    },
    emailValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (max-width: 992px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '21.25%',
        },
        textAlign: 'center',
    },
    periodValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '21.25%',
        },
        textAlign: 'center',
    },
    noRequestFound: {
        marginTop: '10rem',
        textAlign: 'center',
    },
    username: {
        fontWeight: 'bold',
        color: 'blue',
        "@media (max-width: 992px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '21.25%',
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
    titleValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '21.25%',
        },
        textAlign: 'center'
    },
    avatar: {
        "@media (max-width: 992px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '10%',
        },
    },
    avatarTitle: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '10%',
        },
        textAlign: 'center',
    },
    viewBtn: {
        fontWeight: 'bold',
        color: '#BD2A2E',
        "@media (max-width: 600px)": {
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '21.25%',
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
    updateBtn:  {
        fontWeight: 'bold',
        color: 'blue',
        "@media (max-width: 600px)": {
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '21.25%',
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
}); 

function Payout({ setShowBankInfo , setBankInfo, setShowPayout, update }) {
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            let transformArray = [];

            try {
                const requestSnapshot = await firebase
                                                .firestore()
                                                .collection('users')
                                                .where('balance', '>', 0)
                                                .get();

                if (requestSnapshot.size === 0) {
                    setLoading(false)
                    return;
                }

                for (const doc of requestSnapshot.docs) {
                    const accountSnapshot = await firebase
                                                    .firestore()
                                                    .collection('bankAccounts')
                                                    .where('userId', '==', firebase.firestore().collection('users').doc(doc.id))
                                                    .get();

                    accountSnapshot.forEach(account => {
                        transformArray.push({
                            ...doc.data(),
                            id: doc.id,
                            accountId: account.id,
                            ...account.data()
                        })
                    })
                }

                setUsers(transformArray);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                alert(err);
            }
        })()
       
        return () => {};
    }, [update]);

    const lastday = function(y,m){
        return  new Date(y, m +1, 0).getDate();
    }

    const getday = function (d) {
        return moment(new Date().setDate(d)).format('dddd');
    } 

  return (
    <>
        <div className={classes.container}>
            <div className={classes.titleItem}>
                <p className={classes.avatarTitle}>
                    Avatar
                </p>
                <p className={classes.titleValue}>
                    User name
                </p>
                <p className={classes.titleValue}>
                    Balance
                </p>
                <p className={classes.titleValue}>
                    Period
                </p>
                <p className={classes.titleValue}>
                    Bank Info
                </p>
                <p className={classes.titleValue}>
                    Update Info
                </p>
            </div>
            {   loading ?
                    <div className={classes.noRequestFound}>
                        <CircularProgress color="secondary" />
                    </div>
            :   users.length === 0 ?
                    <p className={classes.noRequestFound}>
                        No Users Found.
                    </p>   
            :   <>
                    {   users.map((item, i) => 
                            <div key={i} className={classes.item}>
                                <div className={classes.avatar}>
                                    <Avatar
                                        alt='logo'
                                        src={item.photoURL}
                                        sx={{ 
                                            width: 45, 
                                            height: 45, 
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    />
                                </div>
                                <Link to={`/user/${item.id}`} className={classes.username}>
                                    {item.fname} {item.lname}
                                </Link>
                                <p className={classes.emailValue}>
                                    ${item.balance.toFixed(2)}
                                </p>
                                <p className={classes.periodValue}>
                                    {new Date().getDate() > 15 ? 
                                        getday(lastday(new Date().getFullYear(), new Date().getMonth())) === 'Saturday' ? 
                                            2 + '/' + (new Date().getMonth() + 2) + '/' + new Date().getFullYear() 
                                    :
                                        getday(lastday(new Date().getFullYear(), new Date().getMonth())) === 'Sunday' ? 
                                            1 + '/' + (new Date().getMonth() + 2) + '/' + new Date().getFullYear() 
                                    :
                                        lastday(new Date().getFullYear(), new Date().getMonth()) + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear() 
                                    :
                                        getday(15) === 'Saturday' ? 
                                            17 + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear()
                                        :  getday(15) === 'Sunday' ? 
                                            16 + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear()
                                        :
                                            15 + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear()
                                    }
                                </p>
                                <a 
                                    href="#" 
                                    onClick={() => {
                                        setBankInfo({
                                            userId: item.id,
                                            amount: item.balance,
                                            bankName: item.bankName,
                                            accountNumber: item.accountNumber,
                                            holderName: item.holderName,
                                            transitNumber: item.transitNumber,
                                            branchCode: item.branchCode
                                        })
                                        setShowBankInfo(true);
                                    }} 
                                    className={classes.viewBtn}
                                >
                                    View
                                </a>
                                <a  
                                    href="#"  
                                    onClick={() => {
                                        setBankInfo({
                                            userId: item.id,
                                            amount: item.balance,
                                            bankName: item.bankName,
                                            accountNumber: item.accountNumber,
                                            holderName: item.holderName,
                                            transitNumber: item.transitNumber,
                                            branchCode: item.branchCode
                                        })
                                        setShowPayout(true);
                                    }} 
                                    className={classes.updateBtn}
                                >
                                    Update
                                </a>
                            </div>
                        ) 
                    }
                </>
            }
        </div>
    </>
  )
}

export default Payout