import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
    modal: {
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed', 
        zIndex: 1, 
        left: 0,
        top: 0,
        width: '100%', 
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.4)', 
    },
    modalContent: {
        backgroundColor: '#fefefe',
        padding: '20px',
        border: '1px solid #888',
        "@media (max-width: 600px)": {
            width: '90%'
        },
        "@media (min-width: 600px)": {
            width: '70%'
        },
        "@media (min-width: 768px)": {
            width: '60%'
        },
        "@media (min-width: 992px)": {
            width: '50%'
        },
        "@media (min-width: 1200px)": {
            width: '30%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 15
    },
    closeBtn: {
        marginTop: 20,
        marginBottom: 20,
        cursor: 'pointer'
    },
    detailView: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bankTitle: {
        width: '30%',
        textAlign: 'center',
    },
    bankValues: {
        width: '50%',
        textAlign: 'center',
    }
});

function BankInfo({ setShowBankInfo, bankInfo }) {
    const classes = useStyles();
    const [copy, setCopy] = useState();

    useEffect(() => {
        setTimeout(() => {
            setCopy();
        }, 750);
    }, [copy]);

  return (
    <div className={classes.modal}>
        <div className={classes.modalContent}>
            <CloseIcon  onClick={() => setShowBankInfo(false)} fontSize='medium' color='secondary' className={classes.closeBtn} />
            <div className={classes.detailView}>
                <p className={classes.bankTitle}>
                    Bank Name
                </p>
                <p className={classes.bankValues}>
                    {bankInfo.bankName}
                </p>
                <CopyToClipboard text={bankInfo.bankName} onCopy={() => setCopy(1)}>
                    <ContentPasteIcon fontSize='small' color={copy === 1 ? 'primary' : 'disabled'} />
                </CopyToClipboard>
            </div>
            <div className={classes.detailView}>
                <p className={classes.bankTitle}>
                    Branch Code
                </p>
                <p className={classes.bankValues}>
                    {bankInfo.branchCode}
                </p>
                <CopyToClipboard text={bankInfo.branchCode} onCopy={() => setCopy(2)}>
                    <ContentPasteIcon fontSize='small' color={copy === 2 ? 'primary' : 'disabled'} />
                </CopyToClipboard>
            </div>
            <div className={classes.detailView}>
                <p className={classes.bankTitle}>
                    Transit Number
                </p>
                <p className={classes.bankValues}>
                    {bankInfo.transitNumber}
                </p>
                <CopyToClipboard text={bankInfo.transitNumber} onCopy={() => setCopy(3)}>
                    <ContentPasteIcon fontSize='small' color={copy === 3 ? 'primary' : 'disabled'} />
                </CopyToClipboard>
            </div>
            <div className={classes.detailView}>
                <p className={classes.bankTitle}>
                    Holder Name
                </p>
                <p className={classes.bankValues}>
                    {bankInfo.holderName}
                </p>
                <CopyToClipboard text={bankInfo.holderName} onCopy={() => setCopy(4)}>
                    <ContentPasteIcon fontSize='small' color={copy === 4 ? 'primary' : 'disabled'} />
                </CopyToClipboard>
            </div>
            <div className={classes.detailView}>
                <p className={classes.bankTitle}>
                    Account Number
                </p>
                <p className={classes.bankValues}>
                    {bankInfo.accountNumber}
                </p>
                <CopyToClipboard text={bankInfo.accountNumber} onCopy={() => setCopy(5)}>
                    <ContentPasteIcon fontSize='small' color={copy === 5 ? 'primary' : 'disabled'} />
                </CopyToClipboard>
            </div>
        </div>
    </div>
  )
}

export default BankInfo