import React, { useState, useEffect } from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";

import firebase from "firebase/app";
import 'firebase/firestore';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2rem',
    },
    titleItem: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    item: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        marginLeft:'auto',
        marginRight: 'auto',
        alignItems: 'center',
    },
    noRequestFound: {
        marginTop: '10rem',
        textAlign: 'center',
    },
    emailValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (max-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '1.1rem'
        },
        textAlign: 'center',
    },
    titleValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '26.6%',
        },
        textAlign: 'center',
    },
    username: {
        fontWeight: 'bold',
        color: 'blue',
        "@media (max-width: 600px)": {
           marginTop: '2rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '0'
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
    avatar: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '10%',
        },
        textAlign: 'center',

    },
    avatarValue: {
        "@media (max-width: 600px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '10%',
            marginTop: '0'
        },
    },
    accessBtn: {
        fontWeight: 'bold',
        color: '#042940',
        "@media (min-width: 992px)": {
            width: '21.6%',
        },
        textAlign: 'center',
        textDecoration: 'none'
    }
}); 

function Host() {
    const classes = useStyles();

    const [request, setRequest] = useState([]);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        (async () => {
            setLoading(true);
            let transformArray = [];

            try {
                const requestSnapshot = await firebase.firestore().collection('requests').get();

                if (requestSnapshot.size === 0) {
                    setLoading(false)
                    return;
                }

                for (const doc of requestSnapshot.docs) {
                    const userSnapshot = await firebase.firestore().collection('users').doc(doc.id).get();

                    if(userSnapshot.exists) {
                        transformArray.push({
                            ...doc.data(),
                            ...userSnapshot.data(),
                            id: doc.id
                        })
                    }
                }

                setRequest(transformArray);
                setLoading(false);
            } catch (err) {
                setLoading(false)
                alert(err);
            }
        })()
       
        return () => {};
    }, [update]);

    const filterData = async () => {
        let transformArray = [];

        try {
            const requestSnapshot = await firebase
                                            .firestore()
                                            .collection('requests')
                                            .where('addVehiclePermission', '==', false)
                                            .get();

            if (requestSnapshot.size === 0) {
                setLoading(false)
                return;
            }

            for (const doc of requestSnapshot.docs) {
                const userSnapshot = await firebase.firestore().collection('users').doc(doc.id).get();

                if(userSnapshot.exists) {
                    transformArray.push({
                        ...doc.data(),
                        ...userSnapshot.data(),
                        id: doc.id
                    })
                }
            }

            setRequest(transformArray);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    }

    const access = async (id) => {
        try {
            setLoading(true);

            await firebase
                    .firestore()
                    .collection('requests')
                    .doc(id)
                    .update({
                        addVehiclePermission: true
                    });

            if (filter === '' || filter === 'All') {
                setUpdate(!update);
            } else {
                await filterData();
            }
        } catch (err) {
            setLoading(false)
            alert(err)
        }
    };

    const handleChange = async (event) => {
        setFilter(event.target.value);

        try {
            if (event.target.value === 'All') {
                setUpdate(!update);
                return;
            }

            setLoading(true);

            await filterData();
        } catch (err) {
            setLoading(false);
            alert(err);
        }
    };

  return (
    <>
        <FormControl 
            sx={{ 
                width: 300, 
                marginTop: '8rem', 
                "@media (max-width: 600px)": {
                    marginLeft: 3
                },
                "@media (min-width: 600px)": {
                    marginLeft: 4
                }
            }}
        >
            <InputLabel>Host</InputLabel>
            <Select
                value={filter}
                label='Host'
                onChange={handleChange}
            >
                <MenuItem value={'All'}>All</MenuItem>
                <MenuItem value={'Permission'}>Permissions</MenuItem>
            </Select>
        </FormControl>
        <div className={classes.container}>
            <div className={classes.titleItem}>
                <p className={classes.avatar}>
                    Avatar
                </p>
                <p className={classes.titleValue}>
                    User name
                </p>
                <p className={classes.titleValue}>
                    Email
                </p>
                <p className={classes.titleValue}>
                    Access
                </p>
            </div>
            {   loading ?
                    <div className={classes.noRequestFound}>
                        <CircularProgress color="secondary" />
                    </div>
            :   request.length === 0 ?
                    <p className={classes.noRequestFound}>
                        No Request Found.
                    </p>   
            :   <>
                    {   request.map((item, i) => 
                            <div key={i} className={classes.item}>
                                <div className={classes.avatarValue}>
                                    <Avatar
                                        alt='logo'
                                        src={item.photoURL}
                                        sx={{ 
                                            width: 45, 
                                            height: 45, 
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    />
                                </div>
                                <Link to={`/user/${item.id}`} className={classes.username}>
                                    {item.fname} {item.lname}
                                </Link>
                                <p className={classes.emailValue}>
                                    {item.email}
                                </p>
                                { item.addVehiclePermission ?
                                    <p className={classes.titleValue}>
                                        granted
                                    </p>
                                :
                                    <a href='#' onClick={() => access(item.id)} className={classes.accessBtn}>
                                        Allow
                                    </a>
                                }
                            </div>
                        ) 
                    }
                </>
            }
        </div>
    </>
  )
}

export default Host