import React, { useState, useEffect } from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';

import firebase from "firebase/app";
import 'firebase/firestore';

const useStyles = makeStyles({  
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2rem',
    },
    titleItem: {
        display: 'flex',
        "@media (max-width: 992px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    item: {
        display: 'flex',
        "@media (max-width: 992px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        marginLeft:'auto',
        marginRight: 'auto',
        alignItems: 'center',
    },
    noRequestFound: {
        marginTop: '10rem',
        textAlign: 'center',
    },
    emailValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (max-width: 992px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '23.75rem',
        },
        textAlign: 'center',
    },
    titleValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (max-width: 992px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '23.75rem',
        },
        textAlign: 'center',
    },
    username: {
        fontWeight: 'bold',
        color: 'blue',
        "@media (max-width: 992px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '23.75rem',
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
    avatar: {
        "@media (max-width: 992px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '10%',
        },
    },
    avatarTitle: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '10%',
        },
        textAlign: 'center',
        marginLeft: 10
    },
    insuranceUrl: {
        fontWeight: 'bold',
        color: '#BD2A2E',
        "@media (max-width: 992px)": {
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '23.75rem',
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
    insuranceUrlBtn:  {
        fontWeight: 'bold',
        color: '#BD2A2E',
        "@media (max-width: 992px)": {
            marginTop: '.5rem',
            marginBottom: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '23.75rem',
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
}); 

function DrivingLicense() {
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        (async () => {
            setLoading(true);
            let transformArray = [];

            try {
                const requestSnapshot = await firebase.firestore().collection('users').get();

                if (requestSnapshot.size === 0) {
                    setLoading(false)
                    return;
                }

                for (const doc of requestSnapshot.docs) {
                    transformArray.push({
                        ...doc.data(),
                        id: doc.id
                    })
                }

                setUsers(transformArray);
                setLoading(false);
            } catch (err) {
                setLoading(false)
                alert(err);
            }
        })()
       
        return () => {};
    }, [update]);

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const filterData = async (value) => {
        try {
            let transformArray = [];

            const userSnapshot = await firebase
                                        .firestore()
                                        .collection('users')
                                        .where('licenseStatus', '==', value)
                                        .get();

            if (userSnapshot.size === 0) {
                setUsers([])
                setLoading(false)
                return;
            }

            for (const doc of userSnapshot.docs) {
                transformArray.push({
                    ...doc.data(),
                    id: doc.id
                })
            }

            setUsers(transformArray);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert(err);
        }
    }

    const verify = async (id) => {
        try {
            setLoading(true);

            await firebase
                    .firestore()
                    .collection('users')
                    .doc(id)
                    .update({
                        licenseStatus: 'Verified'
                    });

            if (filter === '' || filter === 'All') {
                setUpdate(!update);
                return;
            }

            await filterData(filter);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    };

    const handleChange = async (event) => {
        setFilter(event.target.value);

        try {
            if (event.target.value === 'All') {
                setUpdate(!update);
                return
            }

           setLoading(true);

           await filterData(event.target.value);
        } catch (err) {
            setLoading(false);
            alert(err);
        }
    };

  return (
    <>
        <FormControl 
            sx={{ 
                width: 300, 
                marginTop: '8rem', 
                "@media (max-width: 600px)": {
                    marginLeft: 2.5
                },
                "@media (min-width: 600px)": {
                    marginLeft: 4.5
                }
            }}
        >
            <InputLabel>Driver License</InputLabel>
            <Select
                value={filter}
                label='Driver License'
                onChange={handleChange}
            >
                <MenuItem value={'All'}>All</MenuItem>
                <MenuItem value={'Not Provided'}>Not Provided</MenuItem>
                <MenuItem value={'Processing'}>Processing</MenuItem>
                <MenuItem value={'Verified'}>Verified</MenuItem>
            </Select>
        </FormControl>
        <div className={classes.container}>
            <div className={classes.titleItem}>
                <p className={classes.avatarTitle}>
                    Avatar
                </p>
                <p className={classes.titleValue}>
                    User name
                </p>
                <p className={classes.titleValue}>
                    Email
                </p>
                <p className={classes.titleValue}>
                    License
                </p>
                <p className={classes.titleValue}>
                    Status
                </p>
            </div>
            {   loading ?
                    <div className={classes.noRequestFound}>
                        <CircularProgress color="secondary" />
                    </div>
            :   users.length === 0 ?
                    <p className={classes.noRequestFound}>
                        No User Found.
                    </p>   
            :   <>
                    {
                        users.map((item, i) => 
                            <div key={i} className={classes.item}>
                                <div className={classes.avatar}>
                                    <Avatar
                                        alt='logo'
                                        src={item.photoURL}
                                        sx={{ 
                                            width: 45, 
                                            height: 45, 
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    />
                                </div>
                                <Link to={`/user/${item.id}`} className={classes.username}>
                                    {item.fname} {item.lname}
                                </Link>
                                <p className={classes.emailValue}>
                                    {item.email}
                                </p>
                                { item.licenseStatus === 'Not Provided' ?
                                    <p className={classes.titleValue}>
                                        {item.licenseStatus}
                                    </p>
                                :
                                    <a href="#" onClick={() => openInNewTab(item.licenseURL)} className={classes.insuranceUrl}>
                                        View
                                    </a>
                                }
                            
                                { item.licenseStatus != 'Processing' ?
                                    <p className={classes.titleValue}>
                                        {item.licenseStatus}
                                    </p>
                                : 
                                    <a href="#" onClick={() => verify(item.id)} className={classes.insuranceUrlBtn}>
                                        Verify License
                                    </a>
                                }
                            </div>
                        ) 
                    }
                </>
            }
        </div>
    </>
  )
}

export default DrivingLicense