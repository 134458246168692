import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import firebase from "firebase/app";
import 'firebase/firestore';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2rem'
    },
    loading: {
        position: 'fixed',
        top: '50%',
        left: '50%'
    },
    info: {
        textAlign: 'center'
    },
    requestViewContainer: {
        marginLeft: '5%'
    },
    requestView: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
        },
        "@media (min-width: 600px)": {
            flexDirection: 'row',
        },
        alignItems: 'center'
    },
    request: {
        marginLeft: 20
    },
    requestBtnContainer: {
        "@media (max-width: 600px)": {
            marginTop: '1rem',
        }
    },
    requestBtn: {
        marginLeft: 20,
        backgroundColor: 'black',
        color: 'white',
        fontSize: '16px',
        borderRadius: '5px',
        cursor: 'pointer',
        padding: '2px 10px'
    }
});

function User() {
    let params = useParams();
    let navigate = useNavigate();
    const classes = useStyles();

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const userSnapshot = await firebase
                                            .firestore()
                                            .collection('users')
                                            .doc(params.userId)
                                            .get();

                if (userSnapshot.exists) {
                    const requestSnapshot = await firebase
                                                    .firestore()
                                                    .collection('requests')
                                                    .doc(params.userId)
                                                    .get();
                    setUser({
                        id: userSnapshot.id,
                        ...userSnapshot.data(),
                        ...requestSnapshot.data()
                    });
                }

                setLoading(false);
            } catch (err) {
                setLoading(false);
                alert(err);
            }
        })();

      return () => {}
    }, [params.userId, update]);

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const hostAccess = async (value) => {
        try {
            setLoading(true);

            if (value === 'Disable') {
                await firebase
                    .firestore()
                    .collection('requests')
                    .doc(params.userId)
                    .update({
                        addVehiclePermission: false
                    });
            } else {
                await firebase
                    .firestore()
                    .collection('requests')
                    .doc(params.userId)
                    .update({
                        addVehiclePermission: true
                    });
            }
            
            setUpdate(!update);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    };

    const reserveCar = async (value) => {
        try {
            setLoading(true);

            if (value === 'Disable') {
                await firebase
                    .firestore()
                    .collection('users')
                    .doc(params.userId)
                    .update({
                        reserveCarPermission: true
                    });
            } else {
                await firebase
                    .firestore()
                    .collection('users')
                    .doc(params.userId)
                    .update({
                        reserveCarPermission: false
                    });
            }
            
            setUpdate(!update);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    };

    const verifyDriver = async (value) => {
        try {
            setLoading(true);

            if (value === 'Verify License') {
                await firebase
                        .firestore()
                        .collection('users')
                        .doc(params.userId)
                        .update({
                            licenseStatus: 'Verified'
                        });
            } else {
                await firebase
                        .firestore()
                        .collection('users')
                        .doc(params.userId)
                        .update({
                            licenseStatus: 'Processing'
                        });
            }
            

            setUpdate(!update);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    };

    const verifyCreditCard = async (value) => {
        try {
            setLoading(true);

            if (value === 'Verify License') {
                await firebase
                        .firestore()
                        .collection('users')
                        .doc(params.userId)
                        .update({
                            insuranceStatus: 'Verified'
                        });
            } else {
                await firebase
                        .firestore()
                        .collection('users')
                        .doc(params.userId)
                        .update({
                            insuranceStatus: 'Processing'
                        });
            }
            

            setUpdate(!update);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    };

    if (loading) {
        return <div className={classes.loading}>
            <CircularProgress color="secondary" />
        </div>
    }

  return (
    <div className={classes.container}> 
        <KeyboardBackspaceIcon onClick={() => navigate(-1)} fontSize='large' sx={{ marginLeft: '4%', marginTop: '2rem' }} />
        <Avatar
            alt='logo'
            src={user?.photoURL}
            sx={{ width: 125, height: 125, marginLeft: 'auto', marginRight: 'auto' }}
        />
        <div>
            <h3 className={classes.info}>{user?.fname} {user?.lname}</h3>
            <h4 className={classes.info}>{user?.email}</h4>
            <h4 className={classes.info}>{user?.dob}</h4>
        </div>
        <div className={classes.requestViewContainer}>
            <h4>
                Reserve Car
            </h4>
            <div className={classes.requestView}>
                <h4 className={classes.request}>
                    Access
                </h4>
                <button onClick={() => reserveCar(!user?.reserveCarPermission ? 'Disable' : 'Enable')} className={classes.requestBtn}>
                    {!user?.reserveCarPermission ? 'Disable' : 'Enable'}
                </button>
            </div>
        </div>
        <div className={classes.requestViewContainer}>
            <h4>
                Host request
            </h4>
            <div className={classes.requestView}>
                <h4 className={classes.request}>
                    Access
                </h4>
                <button onClick={() => hostAccess(user?.addVehiclePermission ? 'Disable' : 'Enable')} className={classes.requestBtn}>
                    {user?.addVehiclePermission ? 'Disable' : 'Enable'}
                </button>
            </div>
        </div>
        <div className={classes.requestViewContainer}>
            <h4>
                Driver License
            </h4>
            <div className={classes.requestView}>
                <h4 className={classes.request}>
                    Status
                </h4>
                <p className={classes.request}>
                    {user?.licenseStatus}
                </p>
                <div className={classes.requestBtnContainer}>
                    { user?.licenseStatus === 'Not Provided' ? 
                        <>
                        </>
                    :
                        <>
                            <button onClick={() => openInNewTab(user?.licenseURL)} className={classes.requestBtn}>
                                View Document
                            </button>
                            <button onClick={() => verifyDriver(user?.licenseStatus !== 'Verified' ? 'Verify License' : 'Cancel Verification')}  className={classes.requestBtn}>
                                { user?.licenseStatus !== 'Verified' ? 'Verify License' : 'Cancel Verification' }
                            </button>
                        </>
                    }
                </div>
            </div>
        </div>
        <div className={classes.requestViewContainer}>
            <h4>
                Credit Card Insurance
            </h4>
            <div className={classes.requestView}>
                <h4 className={classes.request}>
                    Status
                </h4>
                <p className={classes.request}>
                    {user?.insuranceStatus}
                </p>
                <div className={classes.requestBtnContainer}>
                    { user?.insuranceStatus === 'Not Provided' ? 
                        <>
                        </>
                    :
                        <>
                            <button onClick={() => openInNewTab(user?.insuranceURL)} className={classes.requestBtn}>
                                View Document
                            </button>
                            <button onClick={() => verifyCreditCard(user?.insuranceStatus !== 'Verified' ? 'Verify License' : 'Cancel Verification')} className={classes.requestBtn}>
                                { user?.insuranceStatus !== 'Verified' ? 'Verify License' : 'Cancel Verification'}
                            </button>
                        </>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default User