import React, { useState, useEffect } from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";

import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/functions';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2rem',
    },
    titleItem: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    item: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        marginLeft:'auto',
        marginRight: 'auto',
        alignItems: 'center',
    },
    noRequestFound: {
        marginTop: '10rem',
        textAlign: 'center',
    },
    emailValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (max-width: 600px)": {
            marginTop: '2rem',
        },
        "@media (min-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '1.1rem'
        },
        textAlign: 'center',
    },
    lastActiveValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '1.1rem'
        },
        textAlign: 'center',
    },
    titleValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '26.6%',
        },
        textAlign: 'center',
    },
    username: {
        fontWeight: 'bold',
        color: 'blue',
        "@media (max-width: 600px)": {
           marginTop: '2rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '0'
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
    avatar: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '10%',
        },
        textAlign: 'center',

    },
    avatarValue: {
        "@media (max-width: 600px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '10%',
            marginTop: '0'
        },
    },
    accessBtn: {
        fontWeight: 'bold',
        color: '#042940',
        "@media (min-width: 992px)": {
            width: '21.6%',
        },
        textAlign: 'center',
        textDecoration: 'none'
    }
}); 
 
function User() {
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [filter, setFilter] = useState('');
    const [pageToken, setPageToken] = useState(null);

    useEffect(() => {
        setLoading(true);

        (async () => {
            try {
                let result;

                const getInactiveUsers = firebase.functions().httpsCallable('getInactiveUsers');

                if (pageToken) {
                    result = await getInactiveUsers({
                        nextPageToken: pageToken
                    });
                } else {
                    result = await getInactiveUsers({});
                }
            
                const { data } = result;

                setUsers(data.users);

                setPageToken(data.pageToken);

                setLoading(false);
            } catch (err) {
                setLoading(false);
                alert(err);
            }
        })()
    }, [update]);
    
    const filterData = async (value) => {
        try {
            let result;
            let inactiveUsers = [];

            const getInactiveUsers = firebase.functions().httpsCallable('getInactiveUsers');

            if (pageToken) {
                result = await getInactiveUsers({
                    nextPageToken: pageToken
                });
            } else {
                result = await getInactiveUsers({});
            }

            const { data } = result;

            if (value === '30 days') {
                inactiveUsers = data.users.filter(
                    user => Date.parse(user.metadata.lastRefreshTime || user.metadata.lastSignInTime) < (Date.now() - 30 * 24 * 60 * 60 * 1000));
            } else if (value === '60 days') {
                inactiveUsers = data.users.filter(
                    user => Date.parse(user.metadata.lastRefreshTime || user.metadata.lastSignInTime) < (Date.now() - 60 * 24 * 60 * 60 * 1000));
            } else {
                inactiveUsers = data.users.filter(
                    user => Date.parse(user.metadata.lastRefreshTime || user.metadata.lastSignInTime) < (Date.now() - 90 * 24 * 60 * 60 * 1000));
            }

            setUsers(inactiveUsers);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    }

    const handleChange = async (event) => {
        setFilter(event.target.value);

        try {
            if (event.target.value === 'All') {
                setUpdate(!update);
                return;
            }

            setLoading(true);

            setPageToken(null);

            await filterData(event.target.value);
        } catch (err) {
            setLoading(false);
            alert(err);
        }
    };
    
    return (
        <>
            <FormControl 
                sx={{ 
                    width: 300, 
                    marginTop: '8rem', 
                    "@media (max-width: 600px)": {
                        marginLeft: 3
                    },
                    "@media (min-width: 600px)": {
                        marginLeft: 4
                    }
                }}
            >
                <InputLabel>User</InputLabel>
                <Select
                    value={filter}
                    label='User'
                    onChange={handleChange}
                >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={'30 days'}>30 days</MenuItem>
                    <MenuItem value={'60 days'}>60 days</MenuItem>
                    <MenuItem value={'90 days'}>90 days</MenuItem>
                </Select>
            </FormControl>
            <div className={classes.container}>
                <div className={classes.titleItem}>
                    <p className={classes.avatar}>
                        Avatar
                    </p>
                    <p className={classes.titleValue}>
                        User name
                    </p>
                    <p className={classes.titleValue}>
                        Email
                    </p>
                    <p className={classes.titleValue}>
                        Last Active
                    </p>
                </div>
                {   loading ?
                        <div className={classes.noRequestFound}>
                            <CircularProgress color="secondary" />
                        </div>
                :   users.length === 0 ?
                        <p className={classes.noRequestFound}>
                            No Request Found.
                        </p>   
                :   <>
                        {   users.map((item, i) => 
                                <div key={i} className={classes.item}>
                                    <div className={classes.avatarValue}>
                                        <Avatar
                                            alt='logo'
                                            src={item.photoURL}
                                            sx={{ 
                                                width: 45, 
                                                height: 45, 
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        />
                                    </div>
                                    <Link to={`/user/${item.uid}`} className={classes.username}>
                                        {item.displayName}
                                    </Link>
                                    <p className={classes.emailValue}>
                                        {item.email}
                                    </p>
                                    <p className={classes.lastActiveValue}>
                                        {item.metadata?.lastSignInTime}
                                    </p>
                                </div>
                            ) 
                        }
                    </>
                }
            </div>
        </>
    )
}

export default User