import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import firebase from "firebase/app";
import 'firebase/firestore';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2rem',
    },
    titleItem: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '3.5rem',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    titleItem2: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    item: {
        display: 'flex',
        "@media (max-width: 992px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        marginLeft:'auto',
        marginRight: 'auto',
        alignItems: 'center',
    },
    taxBtn: {
        marginTop: '8rem',
        backgroundColor: 'red',
        textAlign: 'center',
        width: 300,
        alignSelf: 'center',
        borderRadius: 10,
        backgroundColor: '#008F8C',
        color: '#fff',
        cursor: 'pointer'
    },
    noRequestFound: {
        marginTop: '10rem',
        textAlign: 'center',
    },
    titleValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (max-width: 992px)": {
            width: '18%',
        },
        "@media (min-width: 600px)": {
            width: '18%',
        },
        "@media (min-width: 992px)": {
            width: '19%',
        },
        textAlign: 'center',
    },
}); 

function Tax({ setShowTax }) {
    const classes = useStyles();

    const [taxes, setTaxes] = useState([]);
    const [loading, setLoading] = useState(false);
   
    useEffect(() => {
        setLoading(true);

        let unsubscribe = firebase
                            .firestore()
                            .collection('tax')
                            .onSnapshot(documentSnapshot => {
                                let transformArray = [];

                                documentSnapshot.forEach(doc => {
                                    transformArray.push({
                                        id: doc.id,
                                        ...doc.data()
                                    });
                                });

                                setTaxes(transformArray);
                                setLoading(false);
                            }, error => {
                                setLoading(false);
                            });

        return () => unsubscribe();
    }, [])

  return (
    <div className={classes.container}>
        <div onClick={() => setShowTax(true)} className={classes.taxBtn}>
            <p>Add Tax</p>
        </div>
        <div>
            <div className={classes.titleItem}>
                <p className={classes.titleValue}>
                    City
                </p>
                <p className={classes.titleValue}>
                    Province
                </p>
                <p className={classes.titleValue}>
                    GST
                </p>
                <p className={classes.titleValue}>
                    PST
                </p>
                <p className={classes.titleValue}>
                    HST
                </p>
            </div>
            {   loading ?
                    <div className={classes.noRequestFound}>
                        <CircularProgress color="secondary" />
                    </div>
            :   taxes.length === 0 ?
                    <p className={classes.noRequestFound}>
                        No Taxes Found.
                    </p>   
            :   <>
                    {   taxes.map((item, i) => 
                            <div key={i} className={classes.titleItem2}>
                                <p className={classes.titleValue}>
                                    {item.city}
                                </p>
                                <p className={classes.titleValue}>
                                    {item.province}
                                </p>
                                <p className={classes.titleValue}>
                                    {item.gst}
                                </p>
                                <p className={classes.titleValue}>
                                    {item.pst}
                                </p>
                                <p className={classes.titleValue}>
                                    {item.hst}
                                </p>
                            </div>
                        ) 
                    }
                </>
            }
        </div>
    </div>
  )
}

export default Tax