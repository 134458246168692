const firebaseConfig = {
    projectId: "riamu-8bf19",
    appId: "1:231820753334:web:554b60fd27c07b88786917",
    apiKey: "AIzaSyAPyczJDQLzmW4dCX7W4u64usJOY5yMo3A",
    authDomain: "riamu-8bf19.firebaseapp.com",
    storageBucket: "riamu-8bf19.appspot.com",
    messagingSenderId: "231820753334",
    measurementId: "G-RSZPWHBV3P"
};

export default firebaseConfig;