import React, { useState } from 'react';
import { makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import firebase from "firebase/app";
import 'firebase/firestore';
import { DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles({
    modal: {
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed', 
        zIndex: 1, 
        left: 0,
        top: 0,
        width: '100%', 
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.4)', 
    },
    modalContent: {
        backgroundColor: '#fefefe',
        padding: '20px',
        border: '1px solid #888',
        "@media (max-width: 600px)": {
            width: '90%'
        },
        "@media (min-width: 600px)": {
            width: '70%'
        },
        "@media (min-width: 768px)": {
            width: '60%'
        },
        "@media (min-width: 992px)": {
            width: '50%'
        },
        "@media (min-width: 1200px)": {
            width: '30%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 15
    },
    closeBtn: {
        marginTop: 20,
        marginBottom: 20,
        cursor: 'pointer'
    },
    input: {
        marginTop: 20,
        marginBottom: 30,
        padding: 15,
        width: '52%',
    },
    datePicker: {
        width: '60%',
    },
    submitBtn: {
        marginTop: 30,
        marginBottom: 10,
        backgroundColor: '#4CAF50', 
        border: 'none',
        color: 'white',
        padding: '15px 32px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px'
    }
});

function UpdatePayout({ setShowPayout, bankInfo, update, setUpdate }) {
    const classes = useStyles();
    const [value, setValue] = useState(new Date());
    const [input, setInput] = useState('');
    const [disable, setDisable] = useState(false);

    const updateUser = async () => {
        try {
            setDisable(true);

            await firebase
                    .firestore()
                    .collection('users')
                    .doc(firebase.firestore().collection('users').doc(bankInfo.userId))
                    .update({
                        balance: parseFloat(bankInfo.amount) - parseFloat(input)
                    });

            await firebase
                    .firestore()
                    .collection('payouts')
                    .where('userId', '==', firebase.firestore().collection('users').doc(bankInfo.userId))
                    .set({
                        userId: firebase.firestore().collection('users').doc(bankInfo.userId),
                        amount: input,
                        accountNumber: `******` + bankInfo?.accountNumber?.substr(bankInfo?.accountNumber?.length - 3),
                        created_at: firebase.firestore.Timestamp.now(),
                        nextPayoutDate: value
                    });

            alert('User Updated Successfully');
            setShowPayout(false);
            setDisable(false);
            setUpdate(!update);
        } catch (err) {
            alert(err.message)
            setDisable(false);
        }
    }

  return (
    <div className={classes.modal}>
        <div className={classes.modalContent}>
            <CloseIcon onClick={() => setShowPayout(false)} fontSize='medium' color='secondary' className={classes.closeBtn} />
            <input placeholder='Amount' value={input} onChange={e => setInput(e.target.value)} className={classes.input} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    renderInput={(props) => <TextField className={classes.datePicker} {...props} />}
                    label='Next Payout Date'
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                />
            </LocalizationProvider>
            <button onClick={updateUser} disabled={disable} className={classes.submitBtn}>
                    Submit
            </button>
        </div>
    </div>
  )
}

export default UpdatePayout